import { fadeSlide, fadeUp } from "components/poses";

import Img from "components/Img";
import PinchZoom from "components/PinchZoom";
import { PoseableText } from "components/poseable";
import React from "react";
import posed from "react-pose";

const Fade = posed.div(fadeSlide);

const Caption = posed(PoseableText)(fadeUp);

const renderContent = ({ item, onLoad, idx, triggerLoad = false }) => {
  const { image } = item;
  return (
    <Img Tag="figure" {...image} onLoad={onLoad} triggerLoad={triggerLoad} />
  );
};

// const renderCaption = ({ isCurrent, caption }) => {
//   if (!caption) {
//     return null;
//   }
//   return (

//   );
// };

export default ({
  item,
  theme,
  reveal = null,
  idx,
  currentIndex,
  onLoad,
  triggerLoad,
  isAltItem = false,
}) => {
  const isCurrent = currentIndex === idx;
  if (!reveal) {
    return (
      <React.Fragment>
        {renderContent({ item, theme, idx, triggerLoad })}
        {item.caption && !isAltItem && (
          <Caption
            fontFamily="Calibre"
            fontWeight="bold"
            fontSize={[16, 18]}
            pt={[3, 5]}
            initialPose={`exit`}
            pose={isCurrent ? `visible` : `hidden`}
          >
            {item.caption}
          </Caption>
        )}
      </React.Fragment>
    );
  }
  switch (reveal) {
    case `zoom`:
      return (
        <React.Fragment>
          <PinchZoom revealColor={theme.revealColor}>
            {renderContent({
              item,
              theme,
              isCurrent,
              onLoad,
              idx,
              triggerLoad,
            })}
          </PinchZoom>
          {item.caption && !isAltItem && (
            <Caption
              fontFamily="Calibre"
              fontWeight="bold"
              fontSize={[16, 18]}
              offset={50}
              pt={[3, 5]}
              initialPose={`exit`}
              pose={isCurrent ? `visible` : `hidden`}
            >
              {item.caption}
            </Caption>
          )}
        </React.Fragment>
      );
    case `fade`:
    default:
      return (
        <React.Fragment>
          <Fade visibledelay={1000} offset={30}>
            {renderContent({
              item,
              theme,
              isCurrent,
              onLoad,
              idx,
              triggerLoad,
            })}
          </Fade>
          {item.caption && !isAltItem && (
            <Caption
              fontFamily="Calibre"
              color={theme.footerTextColor}
              fontWeight="bold"
              fontSize={[16, 18]}
              offset={50}
              pt={[3, 5]}
              initialPose={`exit`}
              pose={isCurrent ? `visible` : `hidden`}
            >
              {item.caption}
            </Caption>
          )}
        </React.Fragment>
      );
  }
};
