import React, { forwardRef } from 'react';
import posed from 'react-pose';

const PosedCirc = posed.circle({
  init: { r: 65 },
  hover: { r: 69 },
  focus: { r: 69 }
});

const PosedPath = posed.path({
  init: { fill: `#89B6CD` },
  hover: { fill: `#FFFFFF` },
  focus: { fill: `#FFFFFF` }
});

const PosedG = posed.g({
  init: { fill: `#89B6CD` },
  hover: { fill: `#FFFFFF` },
  focus: { fill: `#FFFFFF` }
});

const Icon = forwardRef((props, ref) => {
  const { title, showPause = false, ...rest } = props;
  return (
    <svg
      ref={ref}
      height="1em"
      viewBox="0 0 130 130"
      {...rest}
      style={{ overflow: `visible`, width: `auto` }}
    >
      <title>{title}</title>
      <g fill="none" fillRule="evenodd" style={{ overflow: `visible` }}>
        <PosedCirc
          cx={65}
          cy={65}
          r={65}
          transform={`translate(0,0)`}
          fill="#444"
          fillRule="nonzero"
        />
        {showPause ? (
          <PosedG fill="#89B6CD" stroke="#89B6CD">
            <rect width="3" height="19" x="55" y="55" />
            <rect width="3" height="19" x="65" y="55" />
          </PosedG>
        ) : (
          <PosedPath
            fill="#89B6CD"
            d="M60.534 52.036l-2.136 2.166 9.516 10.416-9.876 10.175 2.06 2.24 12.118-12.29z"
          />
        )}
      </g>
    </svg>
  );
});

export default posed(Icon)({
  init: {},
  hover: {}
});
