import * as easings from "d3-ease";

import { Column, Container, PosedSection, Row } from "components/layout";
import React, { PureComponent } from "react";
import resolveLink, { byUrl } from "utils/resolveLink";
import styled, { withTheme } from "styled-components";

import Arrow from "components/icons/Arrow";
import { Box } from "@rebass/grid";
import BreakpointListener from "components/BreakpointListener";
import { PoseableButton } from "components/poseable";
import PropTypes from "prop-types";
import Rect from "@reach/rect";
import SectionAside from "components/typography/SectionAside";
import TextButtonWithArrow from "components/buttons/TextButtonWithArrow";
import VisuallyHidden from "@reach/visually-hidden";
import events from "utils/events";
import { navigate } from "gatsby-link";
import posed from "react-pose";
import trackEvent from "utils/trackEvent";

const Track = styled(
  posed.div({
    label: "track",
    draggable: "x",
    pressable: true,
    dragBounds: ({ w }) => ({ left: -w, right: 0 }),
    latest: {
      x: ({ idx }) => `${idx * -100}%`,
      transition: { ease: easings.easeSinOut, duration: 300 },
    },
  })
)`
  cursor: ew-resize;
  position: relative;
  will-change: transform;
  backface-visibility: hidden;
`;

const Item = styled(
  posed.article({
    focusable: true,
    current: { opacity: 1 },
    next: { opacity: 0.5 },
  })
)`
  position: ${(props) => (props.measurer ? "relative" : "absolute")};
  display: block;
  overflow: hidden;
  width: 100%;
  margin: 0;
  top: 0;
  left: ${(props) => props.idx * 100}%;
  padding-left: 0;
  padding-right: 0;
  backface-visibility: hidden;

  :focus {
    outline: none;
  }

  & img {
    position: relative;
    display: block;
    width: 100%;
    user-select: none;
  }
`;

const StyledButton = styled(
  posed(PoseableButton)({
    focusable: true,
    hoverable: true,
    init: {
      x: 0,
      color: ({ color }) => color,
      scale: 1,
    },
    hover: {
      scale: 1.1,
      x: ({ dir }) => 3 * dir,
      color: ({ hovercolor }) => hovercolor || `#ffffff`,
    },
    press: {
      x: ({ dir }) => 3 * dir,
      scale: 1.05,
      color: ({ hovercolor }) => hovercolor || `#ffffff`,
    },
    focus: {
      x: ({ dir }) => 3 * dir,
      scale: 1.1,
      color: ({ hovercolor }) => hovercolor || `#ffffff`,
    },
    blur: {
      x: 0,
      color: ({ color }) => color,
      scale: 1,
    },
  })
)`
  position: relative;
  cursor: pointer;
  display: inline-block;
  background: transparent;

  :focus {
    outline: none;
    box-shadow: none;
  }

  & svg,
  & svg path {
    fill: currentColor;
  }
`;

const StyledSection = styled(PosedSection)`
  overflow-x: hidden;
  :focus {
    outline: none;
  }
`;

const Trigger = styled.figure`
  position: absolute;
  display: block;
  left: 50%;
  top: 0;
  height: 100%;
  width: 2px;
  background: red;
  z-index: 100;
  visibility: hidden;
`;

const getIndex = (items, start, offset) => {
  const l = items.length;
  let desired = start + offset;

  if (items[desired]) {
    return desired;
  }

  let n = start;
  while (start !== desired) {
    start++;
    n++;
    if (n === l) {
      n = 0;
    }
  }
  return n;
};

class Carousel extends PureComponent {
  static ID = 0;

  static propTypes = {
    theme: PropTypes.object,
    padding: PropTypes.number,
    sideLabel: PropTypes.string,
    showNumbers: PropTypes.bool,
    showLink: PropTypes.bool,
    link: PropTypes.string,
    linkLabel: PropTypes.string,
    nextArrowOffset: PropTypes.number,
    nextArrowColor: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
    width: PropTypes.number,
    ItemRenderer: PropTypes.func.isRequired,
  };

  static defaultProps = {
    padding: 20,
    showNumbers: true,
    showLink: false,
    nextArrowOffset: 0,
    width: 10,
  };

  focused = false;
  canCalculateX = false;

  xStart;
  xEnd;
  xDiff;

  constructor(props) {
    super(props);
    Carousel.ID++;

    this.numItems = props.items.length;
    this.items = props.items;
    this.shortenedItems = props.items.filter((item, idx) => idx !== 0);

    this.state = {
      id: props.id ? `carousel-${props.id}` : `carousel-${Carousel.ID}`,
      prevIndex: props.items.length - 1,
      index: 0,
      nextIndex: getIndex(props.items, 0, 1),
      nextNextIndex: getIndex(props.items, 0, 2),
      dir: 1,
      carouselHeight: 0,
      carouselWidth: 0,
      alternateX: 0,
      mobile: false,
      dragging: false,
      firstImageLoaded: false,
    };
  }

  onBreakpoint = ({ newSize }) => {
    this.setState({ mobile: newSize < 2 });
  };

  componentDidMount() {
    const { id } = this.state;
    this.track = document.getElementById(`${id}-track`);
    this.alternateTrack = document.getElementById(`${id}-alternate-track`);
    this.itemElements = Array.from(this.track.querySelectorAll(`article`));
    this.canCalculateX = true;
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
    this.setState({ mobile: BreakpointListener.size < 2 });
    window.addEventListener("keydown", this.onKeydown);
    window.requestAnimationFrame(this.onAnimationFrame);
  }

  componentWillUnmount() {
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
    window.removeEventListener("keydown", this.onKeydown);
    window.cancelAnimationFrame(this.onAnimationFrame);
  }

  onAnimationFrame = () => {
    if (this.canCalculateX) {
      this.alternateTrack.style.transform = this.track.style.transform;
    }
    window.requestAnimationFrame(this.onAnimationFrame);
  };

  onKeydown = ({ which }) => {
    if (!this.focused) {
      return;
    }
    switch (which) {
      case 37:
        this.prev(null, `carousel_prev_key`);
        break;
      case 39:
        this.next(null, `carousel_next_key`);
        break;
      default:
        break;
    }
  };

  next = (evt, eventName = undefined) => {
    this.setIndex(this.state.index + 1, eventName || `carousel_next_click`);
  };

  prev = (evt, eventName = undefined) => {
    this.setIndex(this.state.index - 1, eventName || `carousel_prev_click`);
  };

  setIndex = (index, eventName = undefined) => {
    if (index >= this.numItems - 1) {
      index = this.numItems - 1;
    } else if (index < 0) {
      index = 0;
    }
    if (eventName) {
      this.setState({ index }, () => {
        trackEvent(eventName, {
          event_category: "carousel",
          value: this.state.index,
        });
      });
      return;
    }
    this.setState({ index });
  };

  onPressStart = (e) => {
    const isTouch = e.changedTouches && e.changedTouches[0];
    if (!isTouch) {
      return;
    }
    const event = e.changedTouches[0];
    this.xStart = event.clientX;
  };

  onPressEnd = (e) => {
    const isTouch = e.changedTouches && e.changedTouches[0];
    if (!isTouch) {
      return;
    }
    const event = e.changedTouches[0];
    this.xEnd = event.clientX;
    this.xDiff = Math.abs(this.xEnd - this.xStart);

    if (this.xDiff < 10 && this.items[this.state.index].link) {
      trackEvent("carousel_mobile_click", {
        event_category: "carousel",
        value: this.state.index,
      });
      const link = this.items[this.state.index].link;
      navigate(
        typeof link === "string"
          ? byUrl(link)
          : link.url
          ? byUrl(link.url)
          : resolveLink(link.id)
      );
    }
  };

  onDragStart = () => {
    clearTimeout(this.dragEndTimeout);
    this.setState({ dragging: false });
  };

  onDragEnd = () => {
    if (!this.trigger) {
      this.setState({ dragging: false });
      return;
    }
    clearTimeout(this.dragEndTimeout);
    const triggerBounds = this.trigger.getBoundingClientRect();
    let closest = null;
    let dist = Number.POSITIVE_INFINITY;

    for (let i = 0; i < this.itemElements.length; i++) {
      let bounds = this.itemElements[i].getBoundingClientRect();
      let diff = Math.abs(triggerBounds.x - (bounds.x + bounds.width * 0.5));
      if (diff < dist) {
        closest = i;
        dist = diff;
      }
    }
    this.setState({ index: null, dragging: false }, () => {
      this.setState({ index: closest, dragging: true }, () => {
        try {
          trackEvent("carousel_drag_end", {
            event_category: "carousel",
            value: this.state.index,
          });
          this.itemElements[this.state.index].focus();
        } catch (e) {
          // nothing
        }
      });
      this.dragEndTimeout = setTimeout(() => {
        this.setState({ dragging: false });
      }, 2000);
    });
  };

  onFocus = () => {
    this.focused = true;
  };

  onBlur = () => {
    this.focused = false;
  };

  render() {
    const {
      sideLabel,
      showNumbers = true,
      showLink,
      link,
      linkLabel,
      padding,
      theme,
      nextArrowOffset = 0,
      nextArrowColor = null,
      width = 10,
      ItemRenderer,
      big = null,
    } = this.props;

    const {
      id,
      index,
      carouselHeight,
      carouselWidth,
      mobile,
      dragging,
      firstImageLoaded,
    } = this.state;

    return (
      <StyledSection
        id={`carousel-${id}-section`}
        tabIndex={0}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        big={big}
        visibleCondition={firstImageLoaded}
      >
        <Container>
          <Row flexWrap={mobile ? `wrap` : `nowrap`}>
            {sideLabel && (
              <SectionAside
                num={sideLabel}
                label={showNumbers ? `${index + 1} — ${this.numItems}` : null}
              />
            )}
            {/* MAIN TRACK */}
            <VisuallyHidden>
              <div aria-live="polite" aria-atomic="true">
                Item {index + 1} of {this.numItems}
              </div>
            </VisuallyHidden>
            <Column width={[1, 1, 1, sideLabel ? width / 12 : 11 / 12]}>
              <fieldset
                style={{
                  border: `none`,
                  position: `absolute`,
                  top: `100%`,
                  left: mobile ? `auto` : 0,
                  right: mobile ? 0 : `auto`,
                }}
                aria-label="Carousel Buttons"
                aria-controls={id}
              >
                <StyledButton
                  aria-label="previous"
                  disabled={index === 0}
                  tabIndex={0}
                  color={nextArrowColor || theme.textColor}
                  padding={padding}
                  offset={nextArrowOffset}
                  offsety={`-16px`}
                  onClick={this.prev}
                  dir={-1}
                  style={{ color: nextArrowColor || theme.textColor }}
                  hovercolor={theme.fgColor}
                >
                  <VisuallyHidden>previous</VisuallyHidden>
                  <Arrow width={46} orientation={-1} />
                </StyledButton>
                <StyledButton
                  aria-label="next"
                  disabled={index >= this.numItems - 1}
                  tabIndex={0}
                  color={nextArrowColor || theme.textColor}
                  padding={padding}
                  offset={nextArrowOffset}
                  offsety={`16px`}
                  dir={1}
                  onClick={this.next}
                  style={{ color: nextArrowColor || theme.textColor }}
                  hovercolor={theme.fgColor}
                >
                  <VisuallyHidden>next</VisuallyHidden>
                  <Arrow width={46} />
                </StyledButton>
              </fieldset>
              <div
                id={id}
                style={{
                  position: `relative`,
                  overflow: `hidden`,
                  height: carouselHeight,
                  zIndex: 10,
                }}
              >
                <Trigger ref={(r) => (this.trigger = r)} />
                <Track
                  id={`${id}-track`}
                  idx={index}
                  poseKey={`${id}-track-${index}`}
                  pose={["latest"]}
                  onDragStart={this.onDragStart}
                  onDragEnd={this.onDragEnd}
                  onPressStart={this.onPressStart}
                  onPressEnd={this.onPressEnd}
                  w={carouselWidth * this.numItems - 1}
                  style={{
                    willChange: `transform`,
                    height: carouselHeight,
                  }}
                >
                  {this.items.map((item, idx) => (
                    <Rect
                      key={`${id}-item-${idx}`}
                      observe={idx === 0}
                      onChange={(rect) => {
                        this.setState({
                          carouselHeight: rect.height,
                          carouselWidth: rect.width,
                        });
                      }}
                    >
                      {({ ref }) => (
                        <Item
                          initialPose={`next`}
                          pose={"current"}
                          measurer={idx === 0}
                          px={0}
                          key={`${id}-item-${idx}`}
                          idx={idx}
                          currentIndex={index}
                          pad={padding}
                          theme={theme}
                          ref={ref}
                        >
                          <ItemRenderer
                            item={item}
                            idx={idx}
                            currentIndex={index}
                            theme={theme}
                            dragging={dragging}
                            reveal={idx === 0 ? `zoom` : null}
                            tabIndex={idx === index ? null : -1}
                            // focusHandler={() => {
                            //   this.setIndex(idx);
                            // }}
                            onLoad={() =>
                              this.setState({ firstImageLoaded: true })
                            }
                          />
                        </Item>
                      )}
                    </Rect>
                  ))}
                </Track>
              </div>
            </Column>
            {/* ALT TRACK */}
            <Column
              width={[1, 1, 1, sideLabel ? width / 12 : 11 / 12]}
              style={{ display: mobile ? `none` : `block` }}
            >
              <div
                style={{
                  position: `relative`,
                  overflow: `hidden`,
                  height: carouselHeight,
                }}
              >
                <div
                  id={`${id}-alternate-track`}
                  style={{
                    position: `relative`,
                    willChange: `transform`,
                    // transition: dragging ? `transform 0.4s ease-out` : `none`,
                    marginLeft: -1,
                    height: carouselHeight,
                  }}
                >
                  {this.shortenedItems.map((item, idx) => {
                    return (
                      <Item
                        initialPose={`next`}
                        pose={"next"}
                        px={0}
                        key={`${id}-altitem-${idx}`}
                        idx={idx}
                        theme={theme}
                        style={{ cursor: `pointer` }}
                        title={`Next`}
                        ariaLabel={`Next`}
                        onClick={this.next}
                      >
                        <ItemRenderer
                          item={item}
                          idx={idx}
                          theme={theme}
                          isAltItem={true}
                          tabIndex={-1}
                          reveal={idx === 0 ? `fade` : null}
                          triggerLoad={this.state.firstImageLoaded === true}
                        />
                      </Item>
                    );
                  })}
                </div>
              </div>
            </Column>
          </Row>
          {showLink && (
            <Box
              ml={[`0px`, `0px`, `0px`, `${(1 / 12) * 100}%`]}
              mt={[70, 80, 90, 100]}
              width={[1, 1, 1, 10 / 12]}
            >
              <TextButtonWithArrow
                visibledelay={500}
                label={linkLabel || link}
                url={byUrl(link)}
                title={linkLabel || link}
              />
            </Box>
          )}
        </Container>
      </StyledSection>
    );
  }
}

export default withTheme(Carousel);
