import { Box, Flex } from "@rebass/grid";
import { Column, Container, PosedSection, Row } from "components/layout";
import React, { PureComponent } from "react";

import BreakpointListener from "components/BreakpointListener";
import Img from "components/Img";
import PinchZoom from "components/PinchZoom";
import { PoseableText } from "components/poseable";
import PropTypes from "prop-types";
import { caption } from "components/typography/sizes";
import cssForBreakpoints from "utils/cssForBreakpoints";
import events from "utils/events";
import { fadeUp } from "components/poses";
import posed from "react-pose";
import styled from "styled-components";

const ImageContainer = styled(Box)`
  ${({ maxWidth }) => `
    ${cssForBreakpoints("max-width", maxWidth)};
  `}
`;

const PosedCaption = styled(posed(PoseableText)(fadeUp))`
  ${caption.css}
`;

export default class ImageGridBlock extends PureComponent {
  static propTypes = {
    theme: PropTypes.object,
    backgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    images: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string.isRequired,
        alt: PropTypes.string,
        title: PropTypes.string,
        fluid: PropTypes.any,
        fixed: PropTypes.any,
      })
    ),
    constrainToGrid: PropTypes.bool,
    captionNode: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }).isRequired,
    }),
  };

  static defaultProps = {
    constrainToGrid: false,
  };

  static ID = 0;

  state = {
    id: null,
    mobile: false,
    imagesLoaded: false,
    firstImageLoaded: false,
  };

  onBreakpoint = ({ newSize }) => {
    this.setState({ mobile: newSize <= 1 });
  };

  componentDidMount() {
    ImageGridBlock.ID++;
    this.numImages = this.props.images ? this.props.images.length : 0;
    this.loadedImages = 0;
    this.setState({
      id: `imagegrid-${ImageGridBlock.ID}`,
      mobile: BreakpointListener.size <= 1,
    });
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
  }

  componentWillUnmount() {
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
  }

  imageLoaded = () => {
    this.loadedImages++;
    if (this.loadedImages === this.numImages) {
      this.setState({ firstImageLoaded: true, imagesLoaded: true });
    } else {
      this.setState({ firstImageLoaded: true });
    }
  };

  renderImages() {
    const {
      images,
      constrainToGrid,
      constrainImageSize = false,
      mobileSize = null,
      tabletSize = null,
      desktopSize = null,
      largeScreenSize = null,
    } = this.props;

    const { id, mobile } = this.state;

    const widthArr = [mobileSize, tabletSize, desktopSize, largeScreenSize].map(
      (item, idx) => {
        if ((idx === 0 && !item) || item === "full width") {
          return 1;
        } else {
          const arr = item.split("/");
          return parseInt(arr[0]) / parseInt(arr[1]);
        }
      }
    );
    const width = constrainImageSize ? widthArr : null;

    return constrainToGrid ? (
      <Row
        flexWrap={mobile ? "wrap" : "nowrap"}
        style={constrainImageSize ? { justifyContent: `center` } : null}
      >
        {images.map((img, idx) => (
          <Column
            width={width}
            key={`${id}-${idx}`}
            style={{ flex: mobile || constrainImageSize ? "none " : 1 }}
          >
            <Img key={`${id}-${idx}`} {...img} onLoad={this.imageLoaded} />
          </Column>
        ))}
      </Row>
    ) : (
      images.map((img, idx) =>
        images.length === 1 ? (
          <Img key={`${id}-${idx}`} {...img} onLoad={this.imageLoaded} />
        ) : (
          <ImageContainer
            key={`${id}-${idx}`}
            is="article"
            width={`${img.width}px`}
            height={`${img.height}px`}
            px={[38]}
            py={[38]}
            maxWidth={[`100%`, `100%`, `50%`]}
          >
            <Img
              {...img}
              onLoad={this.imageLoaded}
              triggerLoad={idx > 0 && this.state.firstImageLoaded === true}
            />
          </ImageContainer>
        )
      )
    );
  }

  render() {
    const {
      backgroundColor,
      images,
      constrainToGrid,

      captionNode,
      theme,
      groupWithNextBlock,
    } = this.props;

    return (
      <PosedSection
        visibleCondition={this.state.imagesLoaded}
        pb={
          groupWithNextBlock ? theme.gap.groupedWithNextBlock : theme.gap.small
        }
      >
        <PinchZoom revealColor={theme.revealColor}>
          <Flex
            flexWrap={["wrap", "wrap", "wrap", "wrap", "nowrap"]}
            px={[40, 80, 100, 120, 140, 165]}
            py={[40, 60, 60, 80, 110]}
            style={{
              backgroundColor: backgroundColor?.hex || theme.colors.black,
            }}
          >
            {constrainToGrid || images.length === 1 ? (
              <Container>{this.renderImages()}</Container>
            ) : (
              this.renderImages()
            )}
          </Flex>
        </PinchZoom>
        {captionNode && (
          <Container>
            <PosedCaption
              is="aside"
              visibledelay={300}
              fontSize={caption.size}
              fontFamily="Calibre"
              fontWeight="bold"
              color={theme.textColor}
              mt={caption.margin}
              dangerouslySetInnerHTML={{
                __html: captionNode.childMarkdownRemark.html,
              }}
            />
          </Container>
        )}
      </PosedSection>
    );
  }
}
