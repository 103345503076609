import React from "react";
import Img from "components/Img";
import { Container, PosedSection } from "components/layout";
import PinchZoom from "components/PinchZoom";
import { PoseableText } from "components/poseable";
import { fadeUp } from "components/poses";
import { caption } from "components/typography/sizes";
import PropTypes from "prop-types";
import { PureComponent } from "react";
import posed from "react-pose";
import styled from "styled-components";

const PosedText = styled(posed(PoseableText)(fadeUp))`
  ${caption.css}
`;

class FullWidthImageBlock extends PureComponent {
  static propTypes = {
    theme: PropTypes.object,
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string,
      title: PropTypes.string,
      fluid: PropTypes.any,
      fixed: PropTypes.any,
    }).isRequired,
    fullBleed: PropTypes.bool,
    captionNode: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }).isRequired,
    }),
  };

  static defaultProps = {
    fullBleed: false,
  };

  state = { imageLoaded: false };

  renderBlock() {
    const { image, theme } = this.props;

    return (
      <PinchZoom revealColor={theme.revealColor}>
        <Img
          {...image}
          onLoad={() => {
            // console.log('fwi imageloaded');
            this.setState({ imageLoaded: true });
          }}
        />
      </PinchZoom>
    );
  }

  render() {
    const { theme, fullBleed, captionNode, groupWithNextBlock } = this.props;
    const { imageLoaded } = this.state;
    return (
      <PosedSection
        data-name="fwi"
        pb={
          groupWithNextBlock ? theme.gap.groupedWithNextBlock : theme.gap.small
        }
        visibleCondition={imageLoaded}
      >
        {fullBleed ? (
          this.renderBlock()
        ) : (
          <Container>{this.renderBlock()}</Container>
        )}
        <Container>
          {captionNode && (
            <PosedText
              is="aside"
              mt={caption.margin}
              visibledelay={300}
              fontFamily="Calibre"
              fontWeight="bold"
              fontSize={caption.size}
              dangerouslySetInnerHTML={{
                __html: captionNode.childMarkdownRemark.html,
              }}
            />
          )}
        </Container>
      </PosedSection>
    );
  }
}

export default FullWidthImageBlock;
