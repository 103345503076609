import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import Rect from "@reach/rect";
import posed from "react-pose";
import styled from "styled-components";
import { Container, Row, PosedSection } from "components/layout";
import { PoseableColumn, PoseableButton } from "components/poseable";
import { fadeUp } from "components/poses";
import ReactPlayer from "react-player/file";
import { Flex, Box } from "@rebass/grid";
import { Heading, Button } from "rebass";
import { smallHeading, normal } from "../typography/sizes";
import GlobalEmitter from "utils/GlobalEmitter";
import events from "utils/events";
import * as easings from "d3-ease";
import VideoPlayIcon from "../buttons/VideoPlayIcon";
import cssForBreakpoints from "utils/cssForBreakpoints";
import BreakpointListener from "components/BreakpointListener";
import { InView } from "react-intersection-observer";

const PosedColumn = posed(PoseableColumn)(fadeUp);

const Player = styled(Flex)`
  background-color: white;
`;

const Scrubber = styled(Box)`
  display: block;
  width: 54%;
  position: relative;
`;

const Track = styled.figure`
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  width: 100%;
  height: 1px;
  display: block;
  z-index: 0;
  cursor: pointer;
  background-color: ${({ bg }) => bg};

  :before {
    content: "";
    position: absolute;
    top: -20px;
    left: 0;
    z-index: 3;
    background: transparent;
    width: 100%;
    height: 40px;
  }

  :after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    background-color: ${({ progressColor }) => progressColor};
    width: ${({ progress }) => progress * 100}%;
    height: 1px;
  }
`;

const Thumb = styled(
  posed(PoseableButton)({
    draggable: `x`,
    dragBounds: ({ tw }) => ({ left: 0, right: tw }),
  })
)`
  position: relative;
  z-index: 2;
  background: transparent;
  padding: 0;
  overflow: visible;
  margin-left: -10px;
  cursor: pointer;

  :before {
    content: "";
    position: absolute;
    overflow: hidden;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    top: -20px;
    left: -20px;
    display: block;
    background: transparent;
  }

  & i {
    display: block;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    background: ${({ bg }) => bg};
    transform: scale(1);
    will-change: transform;
    transition: transform 0.2s ease-in, background-color 0.2s ease-in;
  }

  :hover,
  :focus {
    box-shadow: none;
    outline: none;

    & i {
      transform: scale(1.1);
      background-color: black;
      transition: transform 0.2s ease-out, background-color 0.2s ease-out;
    }
  }
`;

const PlayButtonCircle = styled(
  posed.span({
    hidden: {
      opacity: 0,
      x: -10000,
      transition: {
        opacity: {
          type: `tween`,
          duration: 200,
          ease: easings.easeSinIn,
        },
        x: {
          type: `tween`,
          delay: 201,
          duration: 1,
        },
      },
    },
    disabled: {
      opacity: 0.5,
      x: 0,
      transition: {
        opacity: {
          type: `tween`,
          duration: 300,
          delay: 1,
          ease: easings.easeSinOut,
        },
        x: {
          type: `tween`,
          duration: 1,
        },
      },
    },
    enabled: {
      opacity: 1,
      x: 0,
      transition: {
        opacity: {
          type: `tween`,
          duration: 300,
          delay: 1,
          ease: easings.easeSinOut,
        },
        x: {
          type: `tween`,
          duration: 1,
        },
      },
    },
    hover: {},
    focus: {},
  })
)`
  transform: translateX(-10000) translateZ(0);
  opacity: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  width: 1.3em;
  height: 1.3em;
  overflow: visible;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PlayButton = styled(posed(Button)({ hoverable: true, focusable: true }))`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 2;

  :focus {
    outline: none;
    box-shadow: none;
  }
`;

const padLeft = (string, pad, length) => {
  return (new Array(length + 1).join(pad) + string).slice(-length);
};

const getTime = (time) => {
  if (isNaN(time)) {
    return `0:00`;
  }
  time = Math.round(time);
  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;
  if (isNaN(minutes) || isNaN(seconds)) {
    return `0:00`;
  }
  return padLeft(minutes, "0", 1) + ":" + padLeft(seconds, "0", 2);
};

class AudioPlayer extends PureComponent {
  static ID = 0;

  constructor(props) {
    super(props);
    this.state = {
      id: null,
      entered: false,
      ready: false,
      playing: false,
      hasPlayedOnce: false,
      progress: 0,
      duration: 0,
      durationStr: `0:00`,
      trackX: 0,
      trackWidth: 0,
      mobile: true,
      url: props.url,
    };
  }

  onBreakpoint = ({ newSize }) => {
    this.setState({ mobile: newSize < 2 });
  };

  componentDidMount() {
    AudioPlayer.ID++;
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
    GlobalEmitter.on(events.audioPlayed, this.onAudioPlayed);
    this.setState({ id: AudioPlayer.ID, mobile: BreakpointListener.size < 2 });
  }

  componentWillUnmount() {
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
    GlobalEmitter.off(events.audioPlayed, this.onAudioPlayed);
    this.setState({ playing: false, url: null });
  }

  onAudioPlayed = (id) => {
    if (id === this.state.id) {
      return;
    }
    this.pause();
  };

  onProgress = (e) => {
    const progress = e.played;
    this.setState({ progress: progress || 0 });
  };

  togglePlay = () => {
    this.setState({ playing: !this.state.playing, hasPlayedOnce: true }, () => {
      if (this.state.playing) {
        GlobalEmitter.emit(events.audioPlayed, this.state.id);
      }
    });
  };

  pause = () => {
    this.setState({ playing: false });
  };

  play = () => {
    this.setState({ playing: true }, () => {
      GlobalEmitter.emit(events.audioPlayed, this.state.id);
    });
  };

  onLeave = () => {
    this.pause();
  };

  onTrackClick = (e) => {
    const xPos = e.screenX - this.state.trackX;
    const progress = xPos / this.state.trackWidth;
    this.setState({ progress }, () => {
      this.player.seekTo(progress);
    });
  };

  render() {
    const { theme, title } = this.props;
    const {
      id,
      ready,
      entered,
      trackWidth,
      playing,
      hasPlayedOnce,
      progress,
      duration,
      durationStr,
      mobile,
      url,
    } = this.state;
    return (
      <InView
        as="div"
        rootMargin={mobile ? `-10% 0%` : `-25% 0%`}
        onChange={(inView) => {
          if (inView) {
            this.setState({ entered: true });
          } else {
            this.onLeave();
          }
        }}
      >
        {hasPlayedOnce && (
          <ReactPlayer
            id={`audioplayer-${id}`}
            ref={(r) => (this.player = r)}
            playing={playing}
            url={url}
            controls={false}
            width={0}
            height={0}
            wrapper="figure"
            onProgress={this.onProgress}
            onReady={(e) => {
              const duration = this.player.getDuration();
              this.setState({
                ready: true,
                duration,
                durationStr: getTime(duration),
              });
            }}
            config={{
              file: {
                forceAudio: true,
                attributes: {
                  preload: `auto`,
                },
              },
            }}
            style={{ visibility: `hidden`, width: 0, height: 0 }}
          />
        )}
        <Player
          width={1}
          pl={smallHeading.map((size, idx) => (idx < 3 ? size - 12 : size))}
          pr={smallHeading}
          alignItems={`center`}
          justifyContent={[`flex-start`, `flex-start`, `space-between`]}
        >
          <Box
            width={[`auto`, `auto`, `250px`]}
            mr={[15, 15, 0]}
            ml={[15, 15, 0]}
            css={`
              ${cssForBreakpoints("flex-grow", [2, 2, 0])}
              ${cssForBreakpoints("order", [2, 2, 1])}
            `}
          >
            <Heading
              color={theme.textColor}
              fontSize={normal}
              fontFamily={`Larish`}
              fontWeight={`semibold`}
              py={`1.86666666667em`}
            >
              {title}
            </Heading>
          </Box>
          <Box
            css={`
              ${cssForBreakpoints("order", [1, 1, 2])}
            `}
          >
            <PlayButton
              fontSize={[45, 55]}
              lineHeight={1}
              withParent={false}
              pose={entered ? "enabled" : "disabled"}
              onClick={this.togglePlay}
              style={{ cursor: "pointer" }}
              css={`
                ${cssForBreakpoints("padding", [0, 0, 12])}
              `}
              mr={[0, 0, 15]}
              title={
                ready ? (playing === true ? `Pause` : `Play`) : `Loading...`
              }
            >
              <PlayButtonCircle>
                <VideoPlayIcon
                  showPause={playing === true}
                  title={
                    ready ? (playing === true ? `Pause` : `Play`) : `Loading...`
                  }
                />
              </PlayButtonCircle>
            </PlayButton>
          </Box>
          <Rect
            key={`audio-player-${id}`}
            onChange={(rect) => {
              this.setState({
                trackX: rect.left,
                trackWidth: rect.width,
              });
            }}
          >
            {({ ref }) => (
              <Scrubber
                ref={ref}
                width={[1 / 2]}
                css={`
                  ${cssForBreakpoints("display", ["none", "none", "block"])}
                  ${cssForBreakpoints("order", [3, 3, 3])}
                `}
              >
                <Track
                  bg={theme.textColor}
                  progressColor={theme.fgColor}
                  progress={progress || 0}
                  onClick={this.onTrackClick}
                />
                <Thumb
                  onValueChange={{
                    x: (x) => {
                      const progress = x / trackWidth;
                      this.setState(
                        { playing: false, progress: progress || 0 },
                        () => {
                          if (!this.player) {
                            return;
                          }
                          this.player.seekTo(progress);
                        }
                      );
                    },
                  }}
                  tw={trackWidth}
                  bg={theme.textColor}
                  progress={progress || 0}
                  style={{
                    transform: `translate3d(${Math.round(
                      progress * trackWidth
                    )}px,0, 0)`,
                  }}
                >
                  <i />
                </Thumb>
              </Scrubber>
            )}
          </Rect>
          <Box
            width={[135, `auto`]}
            css={`
              ${cssForBreakpoints("min-width", [0, 170, 170])}
              ${cssForBreakpoints("order", [4, 4, 4])}
            `}
          >
            <Heading
              color={theme.textColor}
              fontSize={normal}
              fontFamily={`Larish`}
              fontWeight={`semibold`}
              textAlign={`right`}
              dangerouslySetInnerHTML={{
                __html: `${getTime(
                  (progress || 0) * (duration || 0)
                )} / ${durationStr}`,
              }}
            />
          </Box>
        </Player>
      </InView>
    );
  }
}

class AudioBlock extends PureComponent {
  render() {
    const { theme, title, file, groupWithNextBlock } = this.props;
    return (
      <PosedSection
        pb={
          groupWithNextBlock ? theme.gap.groupedWithNextBlock : theme.gap.small
        }
      >
        <Container>
          <Row>
            <PosedColumn width={1}>
              <AudioPlayer theme={theme} title={title} url={file.url} />
            </PosedColumn>
          </Row>
        </Container>
      </PosedSection>
    );
  }
}

AudioBlock.propTypes = {
  title: PropTypes.string.isRequired,
  file: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
};

export default AudioBlock;
