import { Column, Container, Row, Section } from "components/layout";
import {
  PoseableBox,
  PoseableHeading,
  PoseableText,
} from "components/poseable";
import React, { PureComponent } from "react";

import BreakpointListener from "components/BreakpointListener";
import Content from "components/typography/Content";
import Img from "components/Img";
import PageHeading from "components/PageHeading";
import cssForBreakpoints from "utils/cssForBreakpoints";
import events from "utils/events";
import { fadeUp } from "components/poses";
import posed from "react-pose";
import styled from "styled-components";

const StyledImage = styled(Img)`
  ${({ w }) => `${cssForBreakpoints("width", w)}`}
  height: 100%;
`;
const HeroContent = posed(PoseableText)();
const SmallHeading = posed(PoseableHeading)(fadeUp);
const PosedBox = posed(PoseableBox)(fadeUp);

class Hero extends PureComponent {
  state = { mobile: false };
  __hasLoaded = false;

  componentDidMount() {
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
    this.setState({ mobile: BreakpointListener.size < 2 });

    // if we don't hit the imageLoaded event in 2.5 seconds it's probably already loaded, trigger the onImageLoaded handler manually
    //this.loadTimeout = setTimeout(this.onImageLoaded, 1000);
  }

  componentWillUnmount() {
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
    clearTimeout(this.loadTimeout);
  }

  onBreakpoint = ({ newSize }) => {
    this.setState({ mobile: newSize < 2 });
  };

  onImageLoaded = () => {
    if (!this.__hasLoaded) {
      this.props.onImageLoaded();
    }
    this.__hasLoaded = true;
  };

  render() {
    const {
      theme,
      client,
      heading,
      paragraphNode,
      image,
      ready,
      color,
      bgColor,
      paragraphColor,
      marginBottom,
      renderAtEnd = null,
    } = this.props;
    const { mobile } = this.state;
    const largeWidth = `calc(50vw + ${(1 / 6) * 100}%)`;

    return (
      <Section
        mt={[-80, -100, -125, -150, -160, -175]}
        mb={marginBottom ? [40, 60, 80] : null}
        style={{ overflowX: `hidden`, backgroundColor: bgColor || null }}
      >
        <Container>
          <Row multiline={mobile ? true : false}>
            <Column
              width={[5 / 6, 5 / 6, 5 / 6, 3 / 4]}
              py={0}
              pb={[1, 2, 3, 4, 5]}
              style={{ zIndex: 1, order: mobile ? 2 : 1 }}
            >
              <HeroContent
                initialPose={`hidden`}
                pose={ready ? `visible` : `hidden`}
                mt={[-62, -89, -109, 0]}
                py={[0, 0, 0, 150, 160, 175]}
                style={{
                  display: `flex`,
                  flexDirection: `column`,
                  justifyContent: `center`,
                  height: `100%`,
                  minHeight: mobile ? null : `100vh`,
                }}
              >
                <SmallHeading
                  offset={5}
                  is="h3"
                  fontFamily={"Calibre"}
                  fontWeight={"bold"}
                  fontSize={[16, 18]}
                  mb={[15, 17, 20]}
                  color={mobile ? `white` : color || theme.textColor}
                >
                  {client.name}
                </SmallHeading>
                <PageHeading
                  is="div"
                  ready={ready}
                  heading={heading.toUpperCase()}
                  containerProps={{ m: 0 }}
                  headingProps={{
                    pt: 0,
                    mb: [25, 30, 35, 40, 50],
                  }}
                  pb={0}
                  color={color || null}
                />
                <PosedBox
                  visibledelay={700}
                  offset={10}
                  is="article"
                  width={[1, 1, 1, 2 / 5]}
                >
                  <Content
                    is="div"
                    colorProp={paragraphColor || null}
                    dangerouslySetInnerHTML={{
                      __html: paragraphNode.childMarkdownRemark.html,
                    }}
                  />
                </PosedBox>
              </HeroContent>
            </Column>
            <Column
              width={[`100vw`, `100vw`, `100vw`, 1]}
              ml={[-12, -28, -28, `-${(5 / 12) * 100}%`]}
              px={[0, 0, 0, 4, 5]}
              py={0}
              pb={[1, 2, 3, 4, 5]}
              style={{ zIndex: 0, order: mobile ? 1 : 2 }}
            >
              <StyledImage
                {...image}
                onLoad={this.onImageLoaded}
                fadeIn={false}
                critical={true}
                w={[`100%`, `100%`, `100%`, largeWidth]}
              />
            </Column>
          </Row>
        </Container>
        {renderAtEnd}
      </Section>
    );
  }
}

export default Hero;
