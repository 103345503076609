import { Container, PosedSection, Row } from "components/layout";

import Content from "components/typography/Content";
import { PoseableColumn } from "components/poseable";
import PropTypes from "prop-types";
import React from "react";
import SectionAside from "components/typography/SectionAside";
import TextButtonWithArrow from "components/buttons/TextButtonWithArrow";
import { fadeUp } from "components/poses";
import posed from "react-pose";

const PosedColumn = posed(PoseableColumn)(fadeUp);

const TextBlock = ({
  theme,
  showAside,
  asidePrefix,
  asideHeading,
  contentNode,
  groupWithNextBlock,
    content=null,
  sectionProps = {},
  columnProps = {},
  contentProps = {},
  ctaLink,
  ctaLabel,
  align = "left",
  debug = false,
  ...props
}) => {
	// console.log({content, contentNode});
  return (
    <PosedSection
      pb={groupWithNextBlock ? theme.gap.groupedWithNextBlock : theme.gap.small}
      {...sectionProps}
    >
      <Container>
        <Row>
          {showAside && (
            <SectionAside
              num={asidePrefix}
              label={asideHeading}
              theme={theme}
            />
          )}
          <PosedColumn
            width={[1, 1, 1, showAside ? 10 / 12 : 1]}
            {...columnProps}
          >
            <Content
              is={props.as || `article`}
              debug={debug}
              {...contentProps}
              dangerouslySetInnerHTML={{
                __html: contentNode?.childMarkdownRemark?.html ? contentNode.childMarkdownRemark.html : content ||``,
              }}
            />
            {ctaLink && ctaLabel && (
              <div
                style={{
                  display: `block`,
                  position: `relative`,
                  textAlign: align,
                }}
              >
                <TextButtonWithArrow
                  as={ctaLink.charAt(0) === `/` ? null : `a`}
                  href={ctaLink.charAt(0) === `/` ? null : ctaLink}
                  url={ctaLink.charAt(0) === `/` ? ctaLink : null}
                  title={ctaLabel}
                  label={ctaLabel}
                  mt={[10, 20, 30, 40]}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                  style={{ flex: `none`, display: `inline-block` }}
                />
              </div>
            )}
          </PosedColumn>
        </Row>
      </Container>
    </PosedSection>
  );
};

TextBlock.propTypes = {
  theme: PropTypes.object,
  showAside: PropTypes.bool.isRequired,
  asidePrefix: PropTypes.string,
  asideHeading: PropTypes.string,
  contentNode: PropTypes.shape({
    childMarkdownRemark: PropTypes.shape({ html: PropTypes.string.isRequired })
      .isRequired,
  }),
  content: PropTypes.string,
  sectionProps: PropTypes.object,
  contentProps: PropTypes.object,
};

export default TextBlock;
