import * as easings from "d3-ease";

import { Column, Container, PosedSection, Row } from "components/layout";
import { PoseableBox, PoseableText } from "components/poseable";
import React, { PureComponent } from "react";

import BreakpointListener from "components/BreakpointListener";
import { Button } from "rebass";
import GlobalEmitter from "utils/GlobalEmitter";
import Img from "components/Img";
import { InView } from "react-intersection-observer";
import PinchZoom from "components/PinchZoom";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import ReactPlayer from "react-player";
import VideoPlayIcon from "components/buttons/VideoPlayIcon";
import { caption } from "components/typography/sizes";
import events from "utils/events";
import { fadeUp } from "../poses";
import posed from "react-pose";
import styled from "styled-components";
import trackEvent from "utils/trackEvent";

const PosedCaption = styled(posed(PoseableText)(fadeUp))`
  ${caption.css}
`;

const getAspectPercent = (aspect) => {
  let aAspect = aspect.split(":");
  let w = parseInt(aAspect[0]);
  let h = parseInt(aAspect[1]);
  return (h / w) * 100;
};

const VideoColumn = styled(Column)`
  & figure {
    position: absolute !important;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;

    width: 100%;
    height: 100%;
  }

  video,
  iframe {
    position: relative;
    visibility: visible !important;
    z-index: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const Overlay = styled(
  posed.div({
    hidden: {
      opacity: 0,
      applyAtEnd: {
        display: `none`,
      },
      transition: {
        type: `tween`,
        duration: 200,
        delay: 50,
        ease: easings.easeSinIn,
      },
    },
    visible: {
      applyAtStart: {
        display: `block`,
      },
      opacity: 1,
    },
  })
)`
  position: absolute !important;
  height: 100%;
  display: block;
  z-index: 1;

  width: 100%;
  height: 100%;

  & img {
    display: block;
  }
`;

const PlayButton = styled(posed(Button)({ hoverable: true, focusable: true }))`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  z-index: 2;

  :focus {
    outline: none;
    box-shadow: none;
  }
`;

const PlayButtonCircle = styled(
  posed.span({
    hidden: {
      opacity: 0,
      x: -10000,
      transition: {
        opacity: {
          type: `tween`,
          duration: 200,
          ease: easings.easeSinIn,
        },
        x: {
          type: `tween`,
          delay: 201,
          duration: 1,
        },
      },
    },
    disabled: {
      opacity: 0.5,
      x: 0,
      transition: {
        opacity: {
          type: `tween`,
          duration: 300,
          delay: 1,
          ease: easings.easeSinOut,
        },
        x: {
          type: `tween`,
          duration: 1,
        },
      },
    },
    enabled: {
      opacity: 1,
      x: 0,
      transition: {
        opacity: {
          type: `tween`,
          duration: 300,
          delay: 1,
          ease: easings.easeSinOut,
        },
        x: {
          type: `tween`,
          duration: 1,
        },
      },
    },
    hover: {},
    focus: {},
  })
)`
  transform: translateX(-10000) translateZ(0);
  opacity: 0;
  background: transparent;
  border: none;
  border-radius: 0;
  width: 1.3em;
  height: 1.3em;
  overflow: visible;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DockedVideoContainer = styled(
  posed(PoseableBox)({
    out: {
      width: 180,
      height: ({ aspect }) => `${Math.floor((aspect / 100) * 180) - 1}px`,
      x: `0`,
      y: `-100%`,
      left: `100%`,
      top: `calc(100% - 30px)`,
      transition: {
        type: `tween`,
        duration: 200,
        ease: easings.easeSinIn,
      },
    },
    in: {
      width: 180,
      height: ({ aspect }) => `${Math.floor((aspect / 100) * 180) - 1}px`,
      x: `-100%`,
      y: `-100%`,
      top: `calc(100% - 30px)`,
      left: `100%`,
      transition: {
        type: `tween`,
        duration: 300,
        ease: easings.easeSinOut,
      },
    },
    playing: {
      width: `70%`,
      height: ({ aspect }) =>
        `${Math.floor((aspect / 100) * window.innerWidth * 0.7)}px`,
      x: `-50%`,
      y: `-50%`,
      top: `50%`,
      left: `50%`,
      transition: {
        delay: 200,
        type: `tween`,
        duration: 500,
        ease: easings.easeSinOut,
      },
    },
  })
)`
  position: fixed !important;
  top: calc(100% - 30px);
  left: auto;
  z-index: 1000;
  overflow: hidden;

  & figure {
    position: absolute !important;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    & iframe {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
`;

const PauseButton = styled.button`
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 3;
  padding: 0;
  margin: 0;
  border: none;

  :focus {
    outline: none;
    box-shadow: none;
  }
`;

const DockedVideoBackground = styled(
  posed.figure({
    hidden: {
      opacity: 0,
      x: -10000,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        x: {
          duration: 0,
        },
        opacity: {
          delay: 1,
          type: `tween`,
          duration: 400,
          ease: easings.easeSinOut,
        },
      },
    },
  })
)`
  position: fixed;
  display: block;
  transform: translateX(-1000) translateZ(0);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
`;

const YOUTUBE_PARAMS = {
  playerVars: {
    showinfo: 0,
    playsinline: 1,
    controls: 1,
    fs: 0,
    rel: 0,
    modestbranding: 1,
  },
};

class VideoBlock extends PureComponent {
  static propTypes = {
    theme: PropTypes.object.isRequired,
    aspect: PropTypes.string,
    video: PropTypes.shape({ url: PropTypes.string.isRequired }),
    videoUrl: PropTypes.shape({
      url: PropTypes.string.isRequired,
      providerUid: PropTypes.string.isRequired,
    }),
    overlayImage: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string,
      title: PropTypes.string,
      fluid: PropTypes.any,
      fixed: PropTypes.any,
    }),
    captionNode: PropTypes.shape({
      childMarkdownRemark: PropTypes.shape({
        html: PropTypes.string.isRequired,
      }).isRequired,
    }),
    anchor: PropTypes.bool,
    loop: PropTypes.bool,
  };

  static defaultProps = {
    aspect: `16:9`,
    anchor: false,
    loop: false,
  };

  static ID = 0;

  constructor(props) {
    super(props);
    this.state = {
      isYoutube: props.videoUrl?.url !== undefined,
      revealed: false,
      playing: false,
      ready: false,
      mobile: false,
      anchorVideoReady: false,
      anchorVideoEnabled: false,
      anchorVideoPlaying: false,
      hasPlayedOnce: false,
      hasCompleted: false,
      overlayImageLoaded: false,
      videoUrl: props.videoUrl?.url || props.video.url,
      isRetrying: false,
      dockedVideoContainer: null,
      showOverlayImage: true,
      showAnchorOverlayImage: true,
    };
    this.retries = 0;
  }

  onKeydown = ({ which }) => {
    switch (which) {
      case 27:
        //ESC
        if (this.state.anchorVideoPlaying) {
          this.setState({ anchorVideoPlaying: false }, () => {
            window.removeEventListener("keydown", this.onKeydown);
          });
        } else if (this.state.playing) {
          this.setState({ playing: false });
        }
        break;
      case 13:
        //ENTER
        this.setState({ playing: !this.state.playing, hasPlayedOnce: true });
        break;
      default:
        break;
    }
  };

  onEnter = () => {
    this.setState(
      {
        entered: true,
        anchorVideoEnabled: false,
        anchorVideoPlaying: false,
      },
      () => {
        this.enterTimeout = setTimeout(() => {
          this.setState({
            playing:
              this.props.loop ||
              this.state.playing === true ||
              this.state.anchorVideoPlaying === true,
          });
        }, 1000);
      }
    );

    window.removeEventListener("keydown", this.onKeydown);
    window.addEventListener("keydown", this.onKeydown);
  };

  onVideoEnded = () => {
    if (this.props.loop) {
      // do nothing
      return;
    }

    this.videoEndedTimeout = setTimeout(() => {
      setTimeout(() => {
        this.setState(
          {
            playing: false,
            anchorVideoPlaying: false,
            showOverlayImage: true,
            showAnchorOverlayImage: true,
          },
          () => {
            if (this.anchorPlayer && !this.state.anchorVideoPlaying) {
              this.anchorPlayer.seekTo(0);
            }
            this.player.seekTo(0);
          }
        );
      }, 0);
    });
  };

  onLeaveTop = () => {
    clearTimeout(this.enterTimeout);
    this.setState({
      playing: false,
      showOverlayImage: true,
      showAnchorOverlayImage: true,
      anchorVideoPlaying: false,
    });
    window.removeEventListener("keydown", this.onKeydown);
  };

  onLeaveBottom = (above) => {
    clearTimeout(this.enterTimeout);
    this.setState({
      playing: false,
      showOverlayImage: true,
      anchorVideoEnabled:
        !this.state.mobile && this.props.anchor && above ? true : false,
      anchorVideoPlaying: false,
      showAnchorOverlayImage: true,
    });
    window.removeEventListener("keydown", this.onKeydown);
  };

  componentDidMount() {
    VideoBlock.ID++;
    BreakpointListener.on(events.breakpoint, this.onBreakpoint);
    GlobalEmitter.on(events.videoPlayed, this.onVideoPlayed);
    this.setState({
      id: VideoBlock.ID,
      mobile: BreakpointListener.size < 2,
      dockedVideoContainer: document.getElementById("docked-video-container"),
    });
  }

  componentWillUnmount() {
    clearTimeout(this.enterTimeout);
    clearTimeout(this.playTimeout);
    clearTimeout(this.videoEndedTimeout);
    clearTimeout(this.retryTimeout);
    BreakpointListener.off(events.breakpoint, this.onBreakpoint);
    GlobalEmitter.off(events.videoPlayed, this.onVideoPlayed);
    window.removeEventListener("keydown", this.onKeydown);
    this.setState({
      videoUrl: null,
      anchorVideoPlaying: false,
      playing: false,
      showOverlayImage: true,
      showAnchorOverlayImage: true,
    });
  }

  onBreakpoint = ({ newSize }) => {
    this.setState({ mobile: newSize < 2 });
  };

  onVideoPlayed = (id) => {
    if (this.props.loop) {
      // keep playing if we're a autoplaying loop video
      return;
    }

    if (this.state.id !== id) {
      this.setState({
        playing: false,
        showOverlayImage: true,
        showAnchorOverlayImage: true,
      });
    }
  };

  scrollToPlayer() {
    const el = this.player.wrapper;
    const bounds = el.getBoundingClientRect();
    const top = bounds.top;

    GlobalEmitter.emit(events.scrollIntoView, { el, centered: true });

    // window.scroll({
    //   top,
    //   left: 0,
    //   behavior: "smooth",
    // });
  }

  onPlayButtonClick = () => {
    this.playTimeout = setTimeout(() => {
      this.setState(
        { playing: true, hasPlayedOnce: true, showOverlayImage: false },
        () => {
          this.emitPlayedEvent();
          // this.playerPauseButton.focus();
          this.playTimeout = setTimeout(() => {
            this.scrollToPlayer();
          }, 1);
        }
      );
    }, 400);

    trackEvent("video_play_clicked", {
      event_category: "video",
      event_label: this.state.videoUrl,
    });
  };

  emitPlayedEvent() {
    GlobalEmitter.emit(events.videoPlayed, this.state.id);
  }

  resetVideoUrl = () => {
    this.setState({ videoUrl: this.props.video.url, isRetrying: false });
  };

  retry() {
    if (this.retries > 2 || this.state.isRetrying) {
      return;
    }
    this.retries++;
    this.setState({ videoUrl: null, isRetrying: true }, () => {
      this.retryTimeout = setTimeout(this.resetVideoUrl, 500);
    });
  }

  render() {
    const {
      theme,
      aspect,
      captionNode,
      overlayImage,
      anchor,
      loop,
      groupWithNextBlock,
    } = this.props;

    const {
      mobile,
      ready,
      playing,
      entered,
      overlayImageLoaded,
      anchorVideoReady,
      anchorVideoPlaying,
      anchorVideoEnabled,
      hasPlayedOnce,
      videoUrl,
      isYoutube,
      showOverlayImage,
      showAnchorOverlayImage,
    } = this.state;

    const shouldRenderPlayer = loop
      ? overlayImageLoaded
      : overlayImageLoaded && hasPlayedOnce;
    const aspectPercent = getAspectPercent(aspect);

    return (
      <React.Fragment>
        <PosedSection
          visibleCondition={this.state.overlayImageLoaded}
          pb={
            groupWithNextBlock
              ? theme.gap.groupedWithNextBlock
              : theme.gap.small
          }
        >
          <InView
            as={Container}
            rootMargin={mobile ? `-10% 0%` : `-25% 0%`}
            onChange={(inView, entry) => {
              const isBottom = entry.boundingClientRect.y < 0;
              if (inView) {
                this.onEnter();
              } else {
                if (isBottom) {
                  this.onLeaveBottom(true);
                } else {
                  this.onLeaveTop();
                }
              }
            }}
          >
            <Row>
              <VideoColumn width={1}>
                <PinchZoom
                  revealColor={theme.revealColor}
                  style={{
                    position: `relative`,
                    display: `block`,
                    width: `100%`,
                    paddingBottom: `${aspectPercent}%`,
                  }}
                  containerStyle={{
                    position: `absolute`,
                    display: `block`,
                    top: 0,
                    left: 0,
                    width: `100%`,
                    height: `100%`,
                  }}
                >
                  {/* {entered && ( */}
                  {shouldRenderPlayer && (
                    <ReactPlayer
                      ref={(r) => (this.player = r)}
                      url={videoUrl}
                      muted={loop}
                      loop={loop}
                      width="100%"
                      height="100%"
                      wrapper="figure"
                      playing={playing}
                      onPlay={() => {
                        clearTimeout(this.pauseTimeout);
                        this.setState({ playing: true });
                      }}
                      onPause={() => {
                        this.pauseTimeout = setTimeout(() => {
                          this.setState({ playing: false });
                          trackEvent("video_pause_clicked", {
                            event_category: "video",
                            event_label: videoUrl,
                          });
                        }, 1000);
                      }}
                      onProgress={() => {
                        if (
                          this.anchorPlayer &&
                          !this.state.anchorVideoPlaying
                        ) {
                          this.anchorPlayer.seekTo(
                            this.player.getCurrentTime()
                          );
                        }
                      }}
                      onReady={() => {
                        if (ready) {
                          return;
                        }
                        this.setState({
                          ready: true,
                        });
                      }}
                      onEnded={this.onVideoEnded}
                      onError={(e) => {
                        try {
                          this.retry();
                        } catch (e) {
                          //
                        }
                      }}
                      config={{
                        file: {
                          forceVideo: true,
                          attributes: {
                            playsInline: "playsinline",
                            preload: entered ? `auto` : `metadata`,
                          },
                        },
                        youtube: YOUTUBE_PARAMS,
                      }}
                    />
                  )}
                  {/* )} */}
                  <React.Fragment>
                    <Overlay
                      initialPose="visible"
                      withParent={false}
                      pose={
                        (playing || hasPlayedOnce) && ready && !showOverlayImage
                          ? "hidden"
                          : "visible"
                      }
                    >
                      <Img
                        {...overlayImage}
                        onLoad={() =>
                          this.setState({ overlayImageLoaded: true })
                        }
                      />
                    </Overlay>
                    {!loop && (
                      <PlayButton
                        fontSize={[40, 80, 100, 115, 130]}
                        lineHeight={1}
                        withParent={false}
                        pose={
                          (isYoutube && !showOverlayImage) ||
                          (!isYoutube && playing)
                            ? "hidden"
                            : overlayImageLoaded
                            ? "enabled"
                            : "disabled"
                        }
                        onClick={
                          overlayImageLoaded ? this.onPlayButtonClick : null
                        }
                        style={{
                          pointerEvents: playing ? "none" : "all",
                          cursor: overlayImageLoaded
                            ? `pointer`
                            : `not-allowed`,
                        }}
                        title={overlayImageLoaded ? `Play` : `Loading...`}
                      >
                        <PlayButtonCircle>
                          <VideoPlayIcon
                            title={overlayImageLoaded ? `Play` : `Loading...`}
                          />
                        </PlayButtonCircle>
                      </PlayButton>
                    )}
                  </React.Fragment>
                </PinchZoom>
              </VideoColumn>
              {captionNode && (
                <Column width={[1]} py={0}>
                  <PosedCaption
                    is="aside"
                    fontSize={caption.size}
                    fontFamily="Calibre"
                    fontWeight="bold"
                    color={theme.textColor}
                    mt={caption.marginBelowColumn}
                    dangerouslySetInnerHTML={{
                      __html: captionNode.childMarkdownRemark.html,
                    }}
                    style={{ display: `block` }}
                    visibledelay={200}
                  />
                </Column>
              )}
            </Row>
          </InView>
        </PosedSection>
        {!mobile &&
          anchor &&
          this.state.dockedVideoContainer &&
          ReactDOM.createPortal(
            <React.Fragment>
              <DockedVideoBackground
                initialPose={`hidden`}
                pose={anchorVideoPlaying ? `visible` : `hidden`}
                onClick={() => {
                  this.setState({
                    anchorVideoPlaying: false,
                    showAnchorOverlayImage: true,
                  });
                  trackEvent("docked_video_background_clicked", {
                    event_category: "video",
                    event_label: videoUrl,
                  });
                }}
              />
              <DockedVideoContainer
                width={180}
                aspect={aspectPercent}
                initialPose={`out`}
                pose={
                  anchorVideoEnabled
                    ? anchorVideoPlaying
                      ? `playing`
                      : `in`
                    : `out`
                }
              >
                {/* {entered && ( */}
                {hasPlayedOnce && (
                  <ReactPlayer
                    ref={(r) => (this.anchorPlayer = r)}
                    url={entered ? videoUrl : null}
                    width="100%"
                    height="100%"
                    wrapper="figure"
                    playing={anchorVideoPlaying}
                    onProgress={() => {
                      if (this.player && !this.state.playing) {
                        this.player.seekTo(this.anchorPlayer.getCurrentTime());
                      }
                    }}
                    onReady={() => {
                      this.setState({ anchorVideoReady: true });
                    }}
                    onPlay={() => {
                      clearTimeout(this.pauseTimeout);
                      this.setState({ anchorVideoPlaying: true });
                    }}
                    onPause={() => {
                      this.pauseTimeout = setTimeout(() => {
                        trackEvent("video_pause_clicked", {
                          event_category: "video",
                          event_label: videoUrl,
                        });
                      }, 1000);
                    }}
                    onEnded={this.onVideoEnded}
                    config={{
                      file: {
                        forceVideo: true,
                        attributes: {
                          playsInline: "playsinline",
                          preload: entered ? `auto` : `none`,
                        },
                      },
                      youtube: YOUTUBE_PARAMS,
                    }}
                  />
                )}
                {/* )} */}
                {/* <PauseButton
                  ref={(r) => (this.anchorPlayerPauseButton = r)}
                  onClick={() => {
                    this.setState({ anchorVideoPlaying: false });
                    trackEvent("docked_video_pause_clicked", {
                      event_category: "video",
                      event_label: videoUrl,
                    });
                  }}
                  style={{ display: anchorVideoPlaying ? `block` : `none` }}
                /> */}
                <Overlay
                  initialPose="visible"
                  pose={
                    anchorVideoPlaying &&
                    anchorVideoReady &&
                    !showAnchorOverlayImage
                      ? "hidden"
                      : hasPlayedOnce &&
                        anchorVideoReady &&
                        !showAnchorOverlayImage
                      ? "hidden"
                      : "visible"
                  }
                >
                  <Img {...overlayImage} />
                </Overlay>
                <PlayButton
                  fontSize={45}
                  lineHeight={1}
                  initialPose="disabled"
                  pose={
                    (isYoutube && !showAnchorOverlayImage) ||
                    (!isYoutube && anchorVideoPlaying)
                      ? "hidden"
                      : overlayImageLoaded
                      ? "enabled"
                      : "disabled"
                  }
                  onClick={() => {
                    this.setState(
                      {
                        anchorVideoPlaying: true,
                        hasPlayedOnce: true,
                        showAnchorOverlayImage: false,
                      },
                      () => {
                        this.emitPlayedEvent();
                        window.removeEventListener("keydown", this.onKeydown);
                        window.addEventListener("keydown", this.onKeydown);
                        // this.anchorPlayerPauseButton.focus();
                        trackEvent("docked_video_play_clicked", {
                          event_category: "video",
                          event_label: videoUrl,
                        });
                      }
                    );
                  }}
                  style={{
                    cursor: overlayImageLoaded ? "pointer" : "not-allowed",
                    pointerEvents: anchorVideoPlaying ? "none" : "all",
                  }}
                >
                  <PlayButtonCircle>
                    <VideoPlayIcon />
                  </PlayButtonCircle>
                </PlayButton>
              </DockedVideoContainer>
            </React.Fragment>,
            this.state.dockedVideoContainer
          )}
      </React.Fragment>
    );
  }
}

export default VideoBlock;
